@import '../../../assets/sass/variables';

$_accent-palette: accent-palette(500);
$_accent-contrast: #8b572a;
$_animation: $animation;
$_item-h-indents: $module-rem / 2;
$_sub-bg: rgba(#fff, 0.07);

.menu {
  --accent: #{$_accent-palette};
  --accent-contrast: #{$_accent-contrast};
  --sub-menu-bg: #{main-palette(500)};

  display: block;
  letter-spacing: 0.2px;
  margin: 0;
  max-width: 100%;
  height: 100%;
  padding: 0;
  transition: 0.2s opacity 0.2s $_animation;
  width: inherit;
  overflow: auto;

  .main-menu-wrap {
    width: inherit;

    .menu-ul {
      list-style-type: none;
      margin: 0;
      padding: ($module-rem * 3) ($module-rem);

      .sub {
        background: $_sub-bg;
        border-radius: 10px;
        list-style-type: none;
        overflow: hidden;
        transition: transform 0.2s $_animation;
        padding: ($module-rem / 2) 0;

        .menu-item {
          align-items: center;
          display: flex;
          margin: 0;
          padding: 0 ($module-rem / 2) 0 ($module-rem * 3.2);

          .item-link {
            .link-text {
              font-size: 1rem;
              opacity: 0.6;
            }
          }
          &:after {
            display: block;
            border: 2px solid currentColor;
            border-radius: 50%;
            content: '';
            height: 8px;
            margin: -4px 0 0;
            opacity: 0.3;
            position: absolute;
            top: 50%;
            transition: opacity 0.2s $_animation;
            left: $module-rem * 1.5;
            width: 8px;
          }
          &:first-child {
            margin-top: $module-rem / 4;
          }
          &:last-child {
            margin-bottom: $module-rem / 4;
          }
          &:hover,
          &.active {
            > .item-link {
              background: none !important;

              .link-text {
                opacity: 1;
              }
            }
            &:after {
              opacity: 1;
            }
          }
          &.active {
            > .item-link {
              .link-text {
                color: $_accent-palette;
                color: var(--accent);
              }
            }
          }
        }
      }
      .menu-item {
        color: #fff;
        cursor: pointer;
        padding: ($module-rem / 4) 0;
        margin: ($module-rem / 2) 0;
        border-radius: 20px;
        position: relative;

        &.group {
          margin: 0;
          padding-bottom: $module-rem / 4;
        }
        .group-title {
          color: $_accent-palette;
          color: var(--accent);
          display: block;
          font-size: 0.9em;
          letter-spacing: 0.8px;
          padding: ($module-rem * 1.5) ($_item-h-indents + ($module-rem / 2)) 0;
          text-transform: uppercase;
        }
        &:first-child {
          .group-title {
            padding-top: 0;
          }
        }
        .item-link {
          align-items: center;
          border-radius: 500px;
          color: currentColor;
          display: flex;
          height: 100%;
          justify-content: space-between;
          min-height: ($module-rem * 3);
          padding: ($module-rem / 2) $_item-h-indents;
          position: relative;
          text-decoration: none;
          transition: background 0.2s $_animation, color 0.2s $_animation, opacity 0.2s $_animation;

          .link-text {
            flex-grow: 2;
            flex-shrink: 1;
            font-size: 1.1em;
            line-height: 1.25;
            margin: 0 ($module-rem / 2);
            transition: color 0.2s $_animation, opacity 0.2s $_animation;
          }
          .link-badge {
            margin: 0 ($module-rem / 2);
          }
          .link-icon {
            color: inherit;
            font-size: 1.2em;
            margin: 1px ($module-rem) 0 ($module-rem / 2);
            min-width: 17px;
            opacity: 0.3;
            padding: 0;
            transition: color 0.2s $_animation, opacity 0.2s $_animation;
          }
          .link-caret {
            margin: 3px ($module-rem / 2) 0;
            font-size: 1.2em;
            transition: transform 0.2s $_animation, color 0.2s $_animation;
          }
        }
        &:hover {
          > .item-link {
            .link-icon {
              opacity: 1;
            }
          }
        }
        &.active:not(.has-sub) {
          > .item-link {
            background: $_accent-palette;
            background: var(--accent);
            color: $_accent-contrast;
            color: var(--accent-contrast);
          }
        }
        &.active {
          > .item-link {
            .link-icon {
            }
            .link-text {
            }
            .link-caret {
              transform: rotate(90deg);
            }
          }
        }
        &.has-sub {
          position: relative;

          > .item-link {
            background: none !important;
            position: relative;

            .caret {
              display: block;
            }
          }
          &:hover,
          &.active {
            > .item-link {
              .link-icon {
                opacity: 1;
              }
              .link-text {
              }
              .caret {
                transform: rotate(90deg);
              }
            }
          }
        }
      }
    }
  }
  &.horizontal {
    @media #{$min992} {
      margin: 0 !important;
      overflow: visible;
    }
    .main-menu-wrap {
      @media #{$min992} {
        .mCustomScrollBox {
          overflow: visible;

          .mCSB_container {
            overflow: visible;
          }
        }
        .mCSB_scrollTools {
          display: none !important;
        }
        .menu-ul {
          display: flex;
          flex-wrap: wrap;
          padding: 0;

          > .menu-item {
            > .group-title {
              display: none;
            }
          }
          .menu-item {
            padding: 0;

            &:after {
              content: '';
              display: none;
              position: absolute;
              width: 100%;
              height: 10px;
              background: transparent;
              z-index: 2;
            }
            .item-link {
              padding: $module-rem $module-rem;

              .link-caret {
                transform: rotate(90deg);
              }
            }
            .sub {
              background: main-palette(500);
              background: var(--sub-menu-bg);
              border-radius: 10px;
              box-shadow: $shadow;
              margin-top: 5px;
              display: block !important;
              height: auto !important;
              left: 0;
              opacity: 0;
              padding: $module-rem 0;
              position: absolute;
              transform: translateY(15px);
              transition: 0.2s opacity 0s $animation, 0s visibility 0.2s $animation,
                transform 0.2s $animation;
              visibility: hidden !important;
              width: $module-rem * 25;
              z-index: 500;

              &:before {
                bottom: ($module-rem * 4);
              }
              .menu-item {
                width: 100%;

                &:after {
                  display: none;
                }
                .item-link {
                  height: auto;
                  min-height: initial;
                  padding-top: $module-rem / 2;
                  padding-bottom: $module-rem / 2;

                  .link-text {
                    &:hover {
                    }
                  }
                }
              }
            }
            &:hover {
              &:after {
                display: block;
              }
              > .sub {
                opacity: 1;
                transform: translateY(5px);
                transition: 0.2s opacity 0s $animation, 0s visibility 0s $animation,
                  transform 0.2s $animation;
                visibility: visible !important;

                &:first-child {
                  margin-top: 5px;
                }
              }
            }
            &.active {
              background: transparent !important;

              &:after {
                display: block;
              }
              > .item-link {
                .link-text {
                }
                .link-icon {
                  opacity: 1;
                }
              }
            }
            &:last-child {
              .sub {
                left: auto;
                right: 0;
              }
            }
            &.full-sub {
              position: static;

              .sub {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
