@import '../../assets/sass/variables';

.vertical {
  .app-container {
    padding-left: $vertical-navbar-width;
    @media #{$max991} {
      padding-left: 0;
    }

    .navbar.horizontal {
      > * {
        margin: 0 $module-rem;
      }
      navbar-skeleton {
        .sk-logo {
          display: none !important;
        }
      }
    }
    .horizontal-nav {
      height: $navbar-height;
      box-shadow: 0 0 10px 0 rgba(#000, 0.15);
    }
  }
}
