@import '../../../assets/sass/variables';

@keyframes loaderAnimation{
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
}
.navbar-skeleton {
  align-items: center;
  background: inherit;
  display: flex;
  height: 100%;
  justify-content: space-between;
  left: 0;
  overflow: hidden;
  padding: 0 $module-rem;
  position: absolute;
  right: 0;
  top: 0;
  transition:
          .2s opacity 0s $animation,
          0s visibility 0s $animation;
  z-index: 999;

  &.invisible {
    opacity: 0;
    transition:
            .2s opacity .2s $animation,
            0s visibility .4s $animation;
    visibility: hidden;
  }
  .bg {
    background: rgba(#fff,.3);
  }
  .bg-1 {
    background: rgba(main-palette(500),.3);
  }
  .bg-2 {
    background: accent-palette(500);
  }
  .bg-3 {
    background: rgba(accent-palette(500),.5);
  }
  .bg-4 {
    background: #ebebeb;
  }
  .left-part {
    flex-grow: 1;
  }
  .navbar-button {
    display: block;
    background: rgba($info-color,.5);
    border-radius: 6px;
    height: 30px;
    margin-right: $module-rem * 2;
    width: 35px;
  }
  .search {
    border-radius: 20px;
    display: block;
    height: 40px;
    max-width: ($module-rem * 75);
    width: 69%;
  }
  .icon-box {
    position: relative;

    .icon {
      border-radius: 5px;
      display: block;
      height: 20px;
      width: 20px;
    }
    .badge {
      background: main-palette(500);
      border-radius: 20px;
      display: block;
      height: 16px;
      position: absolute;
      right: -8px;
      top: -10px;
      width: 16px;
    }
  }
  .avatar {
    border-radius: 20px;
    display: block;
    height: 40px;
    margin: 0 18px 0 25px;
    width: 40px;
  }
  .sk-logo {
    border-radius: 5px;
    display: block;
    height: $module-rem * 4;
    margin-right: $module-rem * 2;
    max-width: 147px;
    width: 100%;
  }
  .animated-bg {
    overflow: hidden;
    position: relative;
  }
  .animated-bg:before {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loaderAnimation;
    animation-timing-function: linear;
    background: transparent;
    background: linear-gradient(to right, transparent 10%, rgba(#fff,.5) 18%, transparent 33%);
    background-size: 800px 100%;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  &.vertical {
    flex-direction: column;

    .logo-block {
      align-items: center;
      background: #fff;
      position: absolute;
      display: flex;
      padding: ($module-rem * 2) ($module-rem * 3);
      height: ($module-rem * 6);
      left: 0;
      top: 0;
      width: 100%;

      .logo {
        border-radius: 6px;
        height: 100%;
        width: 100%;
      }
    }
    .top-part,
    .bottom-part {
      padding: ($module-rem * 5) ($module-rem * 2) ($module-rem * 2);
      width: 100%;
    }
    .bottom-part {
      padding-bottom: $module-rem * 3;
    }
    .sk-logo {
      margin: 0;
    }
    .sk-menu {
      margin: ($module-rem * 4.5) 0 0;

      .sk-menu-item {
        border-radius: 3px;
        display: block;
        height: $module-rem * 1.5;
        margin: ($module-rem * 1.5) 0 0;

        &.menu-header {
          width: 50%;
        }
      }
    }
    .sk-button {
      border-radius: 40px;
      height: $module-rem * 3;
      margin-top: $module-rem * 3;
      margin-left: -$module-rem;
    }
    .sk-group-item {
      border-radius: 3px;
      display: block;
      height: $module-rem * 2;
      margin: 0 $module-rem 0 0;
      width: $module-rem * 8;
    }
    .horizontal-menu {
      align-items: center;
      align-self: flex-start;
      display: none;
      height: 100%;

      .sk-menu-item {
        border-radius: 3px;
        display: block;
        height: $module-rem;
        margin: 0 $module-rem 0 0;
        width: $module-rem * 8;
      }
    }
  }
  .horizontal-vertical & {
    &.vertical {
      @media #{$min992} {
        .top-part,
        .bottom-part {
          display: none;
        }
        .horizontal-menu {
          display: flex;
        }
      }
    }
  }
}
