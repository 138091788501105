html {
  height: 100%;
}
body {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
app {
  display: block;
  height: 100%;
}
