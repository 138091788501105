@import '../variables';

$_bg: (
  default: rgba(#4a505c, 0.08),
  hover: rgba(#4a505c, 0.08),
  focus: #fff,
  disabled: rgba(#ccc, 0.1)
);

$_border: (
  default: rgba(#4a505c, 0.08),
  hover: rgba(#4a505c, 0.08),
  focus: rgba(#4a505c, 0.08),
  disabled: rgba(#ccc, 0.5)
);

$_color: (
  default: #4a505c,
  hover: #4a505c,
  focus: #4a505c,
  disabled: #bdbdbd
);

$_module-rem: $module-rem;
$_placeholder-color: #4a505c;
$_border-style: solid;
$_border-width: 1px;
$_fs: 1rem;
$_lh: $_module-rem * 2 / $_fs;
$_padding-h: $_module-rem / 2;
$_padding-v: $_module-rem / 2;
$_shape: 20px;
$_animation: $animation;

.ant-select {
  width: 100%;

  &.ant-select-sm {
    .ant-select-selection {
      min-height: 32px;
      font-size: 0.9 * $_fs;
    }
  }
  &.ant-select-lg {
    .ant-select-selection {
      border-radius: $_shape * 1.5;
      font-size: 1.1 * $_fs;
      min-height: 50px;
    }
  }

  .ant-select-selection {
    align-items: center;
    background-color: map-get($_bg, default);
    background-clip: padding-box;
    border: $_border-width $_border-style map-get($_border, default);
    box-shadow: none;
    border-radius: $_shape;
    cursor: pointer;
    display: flex;
    position: relative;
    line-height: $_lh;
    min-height: 40px;
    justify-content: flex-start;
    transition: background-color 0.2s $_animation, border 0.2s $_animation, color 0.2s $_animation;
    will-change: background-color, border, color;
    font: inherit;
    font-weight: 400;
    margin: -$_border-width 0;
    outline: none;
    padding: $_padding-v ($_padding-h + 0.2rem);
    width: 100%;

    .ant-select-selection__rendered {
      width: 100%;
    }
  }
  .ant-select-selection__choice {
    border-radius: $_shape;
    background: rgba(74, 80, 92, 0.1);
  }
  .ant-select-selection__clear {
    border-radius: 50%;
    overflow: hidden;
    font-size: 16px;
    height: 16px;
    width: 16px;

    &:hover {
      color: $error-color;
    }
  }
}

.ant-select-dropdown {
  border-radius: $_shape;

  .ant-select-dropdown-menu {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: $module-rem ($module-rem / 2);

    .ant-select-dropdown-menu-item {
      border-radius: 20px;
      display: inline;

      &:not(:last-child),
      &:not(:first-child) {
        margin: ($module-rem / 4) ($module-rem / 2);
      }
    }
    .ant-select-dropdown-menu-item-active {
      background: rgba(157, 92, 229, 0.1);
    }
  }
}

.ant-select-selection--multiple {
  .ant-select-selection__rendered {
    margin-bottom: 0;
  }
  .ant-select-selection__clear {
    top: 18px;
  }
}

.ant-select-search--inline {
  .ant-select-search__field {
    border-radius: $_shape;
  }
}
